import { StaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import PageLayout from '../components/layout/PageLayout';
import LinkButton from '../components/LinkButton';

const Box = styled.div`
  margin: 0 auto;
  padding: 4em 1.5em 4em;
  text-align: center;
  height: 800px;
  
  img {
    display: inline-block;
  }

  .block {
    width: 86%;
    margin:auto;
    display: flex;
    justify-content:space-between;

    @media (max-width: ${props => props.theme.responsive.medium}) {
      width: 100%;

    }
    @media (max-width: ${props => props.theme.responsive.small}) {
      width: 100%;
      flex-direction: column;
    }

  }
`

const Text = styled.p`
  text-align: left;
  line-height: 1.6;
  margin: 1em 0 0  0;
  font-size: 1.5em;

  @media (max-width: ${props => props.theme.responsive.small}) {
    text-align: center;
    font-size: 18px;
  }


`

const Title = styled.h1`
  font-size: 4em;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 2em;
  }
`

const PageNotFound = styled.div`
width: 50%;
text-align: left;

@media (max-width: ${props => props.theme.responsive.small}) {
  text-align: center;
margin-top: 1em;
  width: 100%;
}

`

const Image = styled(Img)`
  width: 37vw;
  object-fit: ${props => props.fit || 'cover'};
  object-position: ${props => props.position || '50% 50%'};  

  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 46%;
    height: 40%;
  }

  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 76%;
    height: 80%;
    margin: auto;
  }

  `

  const ButtonContainer = styled.div`
  min-width: 180px;
  max-width: 352px;
  height: 57px;
  margin-top: 2em;

  a {
    letter-spacing: .3em;
    font-weight: 600;
  }

  @media (max-width: ${props => props.theme.responsive.small}) {
    margin: auto;
    margin-top: 2em;
  }
`


const NotFoundPage = () => (
  <PageLayout >
    <Helmet>
      <title>404 - Page Not Found</title>
      <meta name="description" content="Page not found" />
    </Helmet>
    <Box>
      <div className="block">
      <StaticQuery
        query={query}
        render={data => {
          return (
            data && <Image fluid={data.sorryImage.childImageSharp.fluid} />
          )}}
        />
      <PageNotFound>
        <Title>Looks like you’ve skidded to a stop!</Title>
        <Text>We've launched a new, user-friendly site, so you may have come across an old or missing link. If you can’t find what you’re looking for feel free to <Link to="/contact" >Contact Us</Link>.</Text>
        <ButtonContainer>
          <LinkButton href="/" >Visit Home Page</LinkButton>
        </ButtonContainer>
      </PageNotFound>
      </div>
    </Box>
  </PageLayout>
);

export const query = graphql`
  query {
    sorryImage: file(relativePath: { eq: "sorry-404-page.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`



export default NotFoundPage
